import React from "react"
import styled from "styled-components"

const Icon = styled.svg`
  stroke: lightgrey;
  position: absolute;
  margin: 0 auto;
  transform: scale(0.5);

  path {
    fill: #b6b6b6;
  }
`

export const Arrow = () => {
  return (
    <Icon
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="123.305 0 595.279 595.284"
    >
      <g transform="matrix(1,0,0,1.20561,-9.23706e-14,30.8834)">
        <path
          d="M718.584,397.476c0-10.966-13.319-19.861-29.764-19.861c-101.614,0-434.138,0-535.752,0
		c-16.444,0-29.764,8.895-29.764,19.861c0,15.084,0,35.724,0,50.808c0,10.966,13.319,19.861,29.764,19.861
		c101.614,0,434.138,0,535.752,0c16.444,0,29.764-8.895,29.764-19.861C718.584,433.2,718.584,412.56,718.584,397.476z"
        />
        <path
          d="M718.584,397.476v50.808c0,10.966-13.319,19.861-29.764,19.861H153.069c-16.444,0-29.764-8.895-29.764-19.861v-50.808
		c0-10.966,13.319-19.861,29.764-19.861h535.752C705.265,377.615,718.584,386.51,718.584,397.476z"
        />
      </g>
      <g transform="matrix(1,0,0,1.20561,-9.23706e-14,30.8834)">
        <path
          d="M718.584-5.756c0-10.966-13.319-19.861-29.764-19.861c-101.614,0-434.138,0-535.752,0
		c-16.444,0-29.764,8.895-29.764,19.861c0,15.084,0,35.724,0,50.808c0,10.966,13.319,19.861,29.764,19.861
		c101.614,0,434.138,0,535.752,0c16.444,0,29.764-8.895,29.764-19.861C718.584,29.968,718.584,9.328,718.584-5.756z"
        />
        <path
          d="M718.584-5.756v50.808c0,10.966-13.319,19.861-29.764,19.861H153.069c-16.444,0-29.764-8.895-29.764-19.861V-5.756
		c0-10.966,13.319-19.861,29.764-19.861h535.752C705.265-25.617,718.584-16.722,718.584-5.756z"
        />
      </g>
      <g transform="matrix(1,0,0,1.20561,-9.23706e-14,30.8834)">
        <path
          d="M718.584,195.86c0-10.966-13.319-19.861-29.764-19.861c-101.614,0-434.138,0-535.752,0
		c-16.444,0-29.764,8.895-29.764,19.861c0,15.084,0,35.724,0,50.808c0,10.966,13.319,19.861,29.764,19.861
		c101.614,0,434.138,0,535.752,0c16.444,0,29.764-8.895,29.764-19.861C718.584,231.584,718.584,210.944,718.584,195.86z"
        />
        <path
          d="M718.584,195.86v50.808c0,10.966-13.319,19.861-29.764,19.861H153.069c-16.444,0-29.764-8.895-29.764-19.861V195.86
		c0-10.966,13.319-19.861,29.764-19.861h535.752C705.265,176,718.584,184.894,718.584,195.86z"
        />
      </g>
    </Icon>
  )
}

export const Close = () => {
  return (
    <Icon
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="123.305 0 595.279 595.284"
    >
      <path
        d="M702.144,502.701c-40.031-40.032-119.761-119.762-204.541-204.541C578.785,216.977,655.161,140.601,696.2,99.562
	c1.532-1.532,3.015-3.015,4.447-4.447c14.833-14.833,19.266-34.431,9.917-43.779L667.251,8.022
	c-9.349-9.349-28.946-4.917-43.779,9.917l-9.584,9.584c-42.071,42.071-115.551,115.55-193.461,193.461
	C342.92,143.477,269.944,70.501,228.065,28.622l-9.592-9.592C203.628,4.185,184.02-0.257,174.672,9.092l-4.677,4.677
	c-0.245,0.245-0.49,0.49-0.737,0.737l-16.243,16.242c-1.811,1.811-3.62,3.62-5.415,5.415l-0.577,0.577
	c-5.548,5.548-10.929,10.929-15.666,15.666c-9.348,9.349-4.906,28.956,9.939,43.802c22.217,22.217,56.662,56.662,97.412,97.412
	l7.964,7.964c29.864,29.864,62.792,62.792,96.578,96.578c-84.213,84.214-163.255,163.256-203.045,203.045
	c-14.833,14.834-19.265,34.431-9.917,43.779c12.859,12.859,30.455,30.455,43.314,43.313c9.349,9.349,28.946,4.918,43.779-9.916
	c12.174-12.174,28.021-28.021,46.567-46.567c42.072-42.071,98.03-98.029,156.479-156.478c34.692,34.691,68.54,68.539,99.166,99.165
	l7.965,7.965c40.749,40.749,75.193,75.193,97.411,97.411c14.846,14.845,34.453,19.287,43.801,9.938
	c12.859-12.858,30.455-30.454,43.314-43.313C721.432,537.154,716.99,517.547,702.144,502.701z"
      />
    </Icon>
  )
}
