export const list = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "Oferta",
    path: "/oferta",
  },
  {
    name: "O nas",
    path: "/onas",
  },
  {
    name: "Blog",
    path: "/blog",
  },
  {
    name: "Kontakt",
    path: "/kontakt",
  },
]
