module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Idealne upominki na dzień kobiet","short_name":"Idealne upominki na dzień kobiet","description":"Kwiaty na dzień kobiet - idealny prezent dla każdej kobiety. Upominek na dzień kobiet.","lang":"pl","display":"standalone","icon":"src/assets/images/icon.png","start_url":"/","prefer_related_applications":true,"background_color":"#d60040","theme_color":"#d60040","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"f731931a1b2019c60b675f51a2551a4e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
